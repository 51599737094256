import * as amplitude from '@amplitude/analytics-browser';
import { AnalyticsTypes, EventContext, getAttributionData } from '@/redux/modules/analytics';
import { BodyPrimary, H1 } from '@liveauctioneers/hammer-ui-core/text';
import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { Checkbox } from '@liveauctioneers/hammer-ui-core/checkbox';
import { CompleteAccountModalContext, openCompleteAccountSignUpModal } from '@/redux/modules/modal';
import { FormattedMessage, useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { getAmplitudePageNameFromPath } from '@/utils/getPageNameFromPath';
import { getBidderExists } from '@/redux/modules/bidder/exists/bidderExists.selectors';
import {
    getCreateAccountError,
    getCreateAccountSubmitted,
} from '@/redux/modules/account/createAccount/createAccount.selectors';
import { GlobalState } from '@/redux/store';
import { email as isValidEmail } from '@/utils/validation';
import { submitCreateAccount } from '@/redux/modules/account/createAccount/createAccount.actions';
import { SubtleRouteLink } from '../RouteLinks/RouteLinks';
import { TextInput } from '@liveauctioneers/hammer-ui-core/textInput';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useKameleoon } from '@/hooks/kameleoon/useKameleoon';
import { useLocation } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
import usePrevious from '@liveauctioneers/caterwaul-components/lib/hooks/usePrevious';
import useSetState from '@/hooks/useSetState';

const JoinContainer = () => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const [state, setState] = useSetState({
        email: '',
        emailValid: true,
        termsAccepted: false,
    });

    const prevState = usePrevious(state);

    const location = useLocation();
    const attributionData = useAppSelector((state: GlobalState) => getAttributionData(state, location));
    const bidderExists = useAppSelector((state: GlobalState) => getBidderExists(state));
    const createAccountError = useAppSelector(getCreateAccountError);
    const createAccountSubmitted = useAppSelector(getCreateAccountSubmitted);
    const { visitorCode } = useKameleoon();
    const pageName = getAmplitudePageNameFromPath(location.pathname);

    const onEmailChange = React.useCallback<React.FormEventHandler<HTMLInputElement>>(
        (event) => {
            setState({ email: event.currentTarget.value });
        },
        [setState]
    );

    const openLoginModal = React.useCallback(
        () =>
            dispatch(
                openCompleteAccountSignUpModal({
                    eventContext: EventContext.homepageBanner,
                    eventName: AnalyticsTypes.Track.StartedLogIn,
                    id: 0,
                    type: CompleteAccountModalContext.Join,
                })
            ),
        [dispatch]
    );

    const openSignupModal = React.useCallback(() => {
        dispatch(
            openCompleteAccountSignUpModal({
                eventContext: EventContext.homepageBanner,
                eventName: AnalyticsTypes.Track.StartedAccountCreation,
                id: 0,
                type: CompleteAccountModalContext.Login,
            })
        );
    }, [dispatch]);

    const onJoinWithEmail = React.useCallback(() => {
        const email = state.email.trim();
        const emailValid = isValidEmail(email);
        const agree = state.termsAccepted;
        setState({ emailValid });
        if (emailValid) {
            dispatch(
                submitCreateAccount({
                    agree,
                    attributionData,
                    deviceId: amplitude.getDeviceId()?.toString(),
                    emailAddress: email,
                    onExistingAccount: openLoginModal,
                    onSuccessCallback: openLoginModal,
                    pageName,
                    sessionId: amplitude.getSessionId()?.toString(),
                    shouldOpenModal: false,
                    url: window.location.href,
                    visitorCode,
                })
            );
        }
    }, [attributionData, dispatch, openLoginModal, pageName, setState, state.email, state.termsAccepted, visitorCode]);

    const toggleTermsAccepted = React.useCallback(() => {
        setState({ termsAccepted: !state.termsAccepted });
    }, [setState, state.termsAccepted]);

    const errorOnPage = (!state.emailValid && state.email.length !== 0) || (createAccountError && !bidderExists);

    return (
        <Container>
            <H1 className="mt-6">
                <FormattedMessage id="lets_go_treasure_hunting" />
            </H1>
            <BodyPrimary className="mb-[14px] w-full max-w-[428px]">
                <FormattedMessage id="discover_and_win" />
            </BodyPrimary>
            <StyledInputContainer>
                <div
                    className="mb-md flex"
                    suppressHydrationWarning
                >
                    <StyledTextInput
                        aria-label={formatMessage({
                            id: 'signUpModal.ariaLabel.emailInput',
                        })}
                        data-testid="emailInput"
                        hasError={errorOnPage}
                        id="join-container-email-input"
                        onChange={onEmailChange}
                        placeholder={formatMessage({
                            id: 'signUpModal.enterEmail',
                        })}
                    />
                    <Button
                        className="h-[42px] w-[100px]"
                        data-testid="joinButton"
                        disabled={
                            createAccountSubmitted || prevState === undefined
                                ? null
                                : (state.email.length === 0 && prevState.email.length === 1) ||
                                  (state.termsAccepted === false && state.email.length !== 0)
                        }
                        loading={createAccountSubmitted}
                        onClick={state.email.length === 0 ? openSignupModal : onJoinWithEmail}
                    >
                        {formatMessage({ id: 'setPasswordModal.submit' })}
                    </Button>
                </div>
                {(state.email.length > 0 || state.termsAccepted === true) && (
                    <Checkbox.Option
                        checked={state.termsAccepted}
                        name="adBannerTermsAcceptedCheckbox"
                        onChange={toggleTermsAccepted}
                    >
                        <FormattedMessage
                            id="signUpModal.acceptText"
                            values={{
                                cookieLink: (
                                    <SubtleRouteLink
                                        key="join-container-cookie-link"
                                        onClick={(e) => e.preventDefault()}
                                        rel="noopener"
                                        target="_blank"
                                        to="/cookie-policy"
                                    >
                                        <FormattedMessage id="signUpModal.cookieLink" />
                                    </SubtleRouteLink>
                                ),
                                tosLink: (
                                    <SubtleRouteLink
                                        key="join-container-terms-link"
                                        onClick={(e) => e.preventDefault()}
                                        rel="noopener"
                                        target="_blank"
                                        to="/termsandconditions"
                                    >
                                        <FormattedMessage id="signUpModal.termsLink" />
                                    </SubtleRouteLink>
                                ),
                            }}
                        />
                    </Checkbox.Option>
                )}
            </StyledInputContainer>
        </Container>
    );
};

export default JoinContainer;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 220px;
    width: 490px;
    position: relative;
    margin-right: 30px;

    @media (max-width: 930px) {
        height: auto;
        width: 100%;
        padding: 0;
        text-align: center;
        align-items: center;
    }
`;

const StyledInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 487px;

    @media (max-width: 930px) {
        margin: 0 auto;
    }
`;

const StyledTextInput = styled(TextInput)`
    flex-grow: 1;
    @media (max-width: 930px) {
        width: 100%;

        span {
            display: flex;
        }
    }
`;
