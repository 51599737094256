import * as React from 'react';
import { addImageParams } from '@/utils/urls';
import { Caption, H4 } from '@liveauctioneers/caterwaul-components/lib/Text/Text';
import { featuredCategoriesSelector } from '@/redux/modules/featuredCategories';
import { FeaturedCategory } from '@/types/FeaturedCategory';
import { trackFeaturedCategoryClick } from '@/redux/modules/analytics';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import styled from 'styled-components';

const imageWidth = 165;
const imageHeight = 110;

const HeroBannerFeaturedCardList = () => {
    const dispatch = useAppDispatch();
    const categories = useAppSelector(featuredCategoriesSelector);
    const categoriesToShow = categories.slice(0, 4);

    const trackClick = React.useCallback((label) => dispatch(trackFeaturedCategoryClick(label)), [dispatch]);

    const featuredCards = categoriesToShow.map(({ imageUrl, label, link, title }: FeaturedCategory, index: number) => {
        const src = addImageParams({
            height: imageHeight,
            url: imageUrl,
            width: imageWidth,
        });
        const highDpiSrc = addImageParams({
            height: imageHeight * 2,
            url: imageUrl,
            width: imageWidth * 2,
        });

        return (
            <StyledFeaturedListItem
                data-testid="categoryCard"
                key={`${title}-${index}`}
                onClick={() => trackClick(title)}
            >
                <StyledFeaturedCard
                    route
                    to={link}
                >
                    <StyledImageWrapper>
                        <StyledImage
                            alt={title}
                            height={imageHeight}
                            src={src}
                            srcSet={`${highDpiSrc} 2x`}
                            width={imageWidth}
                        />
                    </StyledImageWrapper>
                    <StyledTitle data-testid="categoryTitle">{title}</StyledTitle>
                    <StyledCaption>{label}</StyledCaption>
                </StyledFeaturedCard>
            </StyledFeaturedListItem>
        );
    });

    return (
        <StyledFeaturedListContainer>
            <StyledFeaturedList>{featuredCards}</StyledFeaturedList>
        </StyledFeaturedListContainer>
    );
};

export default HeroBannerFeaturedCardList;

const StyledFeaturedListContainer = styled.div`
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    width: 57%;

    @media (max-width: 930px) {
        width: 100%;
    }
`;

const StyledFeaturedList = styled.div`
    display: flex;
    align-items: baseline;

    margin-left: 20px;
    width: 100%;
    padding-top: 10px;

    @media (max-width: 930px) {
        margin-left: 0;
        padding-top: 0;
    }
`;

const StyledFeaturedListItem = styled.div`
    flex: 1 1 0px;
    text-align: center;
    margin-top: 22px;

    // hide the 4th card when below 1100px
    @media (max-width: 1100px) {
        &:nth-child(4) {
            display: none;
        }
    }
`;

const StyledCaption = styled(Caption)`
    && {
        color: ${({ theme }) => theme.colors.grey100};
        ${/* sc-selector */ StyledFeaturedListItem}:hover & {
            color: ${({ theme }) => theme.colors.blue200};
        }
    }
`;

const StyledFeaturedCard = styled(Link)`
    && {
        background-color: none;
        text-decoration: none;

        &:hover span {
            color: ${({ theme }) => theme.colors.blue200};
        }
    }
`;

const StyledImageWrapper = styled.div`
    padding-bottom: 66.66666%;
    position: relative;
`;

const StyledImage = styled.img`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: block;
    max-height: 100%;
    max-width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
`;

const StyledTitle = styled(H4)`
    padding: 16px 0 0 0;
    text-align: center;
    display: block;

    ${/* sc-selector */ StyledFeaturedListItem}:hover & {
        color: ${({ theme }) => theme.colors.blue200};
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileWidth}) {
        padding: 10px 0 0 0;
    }
`;
