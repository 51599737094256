import { getBaseUrl } from '@/redux/modules/config';
import { mobileLogo } from '@/utils/urls';
import { useAppSelector } from '@/redux/hooks';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import PageHeaderMeta from '@liveauctioneers/caterwaul-components/lib/PageHeaderMeta/PageHeaderMeta';
import React from 'react';
import useAddNotificationCountToTitle from '@/hooks/useAddNotificationCountToTitle';

const HomePageHeaderMeta = () => {
    const { formatMessage } = useIntl();
    const baseUrl = useAppSelector(getBaseUrl);

    const title = useAddNotificationCountToTitle(formatMessage({ id: 'default_title' }));
    const description = formatMessage({ id: 'get_in_on_the_auction' });

    let canonical = `${baseUrl}`;
    if (canonical.endsWith('/en-gb')) {
        canonical = `${canonical}/`;
    }

    return (
        <PageHeaderMeta
            canonical={canonical}
            description={description}
            title={title}
        >
            <script type="application/ld+json">
                {`{
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "LiveAuctioneers",
                        "url": "https://www.liveauctioneers.com",
                        "logo": "${mobileLogo}",
                        "sameAs" : [ "https://www.facebook.com/LiveAuctioneers/",
                            "https://twitter.com/liveauctioneers",
                            "https://www.pinterest.com/liveauctioneers/",
                            "https://www.linkedin.com/company/liveauctioneers/",
                            "https://www.instagram.com/liveauctioneers/",
                            "https://www.crunchbase.com/organization/live-auctioneers"
                        ]
                    }`}
            </script>
            <script type="application/ld+json">
                {`{
                        "@context": "https://schema.org",
                        "@type": "WebSite",
                        "url": "https://www.liveauctioneers.com/",
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://www.liveauctioneers.com/search/?keyword={search_term_string}",
                            "query-input": "required name=search_term_string"
                        }
                    }`}
            </script>
        </PageHeaderMeta>
    );
};

export default HomePageHeaderMeta;
