import * as React from 'react';
import { getTrendingItems } from '@/redux/modules/home/home.selectors';
import { trackTrendingItemClick } from '@/redux/modules/analytics';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import ItemShelf from '@/components/ItemShelf/ItemShelf';

type Props = {
    className?: string;
};

const TrendingItemsCarousel = ({ className }: Props) => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const itemIds = useAppSelector(getTrendingItems);

    const handleClick = React.useCallback((itemId) => dispatch(trackTrendingItemClick(itemId)), [dispatch]);

    return (
        <ItemShelf
            className={className}
            identifier="trendingItems"
            itemIds={itemIds}
            testid="trendingItemsCarousel"
            title={formatMessage({ id: 'trending_items' })}
            trackItemClick={handleClick}
        />
    );
};

export default React.memo<Props>(TrendingItemsCarousel);
