import * as React from 'react';
import { fetchCatalogPresence, fetchLiveCatalogStatusIfNeeded } from '@/redux/modules/liveCatalogStatus';
import { getCatalogSellerId, getShouldShowLive } from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getLiveCatalogStatus } from '@/redux/modules/console';
import { trackFeaturedCatalogClick, trackFeaturedCatalogView } from '@/redux/modules/analytics';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import CatalogCard from '../CatalogCard/CatalogCard';
import usePrevious from '@liveauctioneers/caterwaul-components/lib/hooks/usePrevious';
import useVisibilitySensor from '@rooks/use-visibility-sensor';

type Props = {
    cardClassname: string;
    catalogId: number;
    className?: string;
    lazyLoadImages?: boolean;
};

const FeaturedCatalogCardWrapper = ({ cardClassname, catalogId, className, lazyLoadImages }: Props) => {
    const dispatch = useAppDispatch();
    const adRef = React.useRef<HTMLDivElement>(null);
    const [visibilityMessageSent, setVisibilityMessageSent] = React.useState(false);

    const trackClick = React.useCallback(() => dispatch(trackFeaturedCatalogClick(catalogId)), [catalogId, dispatch]);

    const { isVisible } = useVisibilitySensor(adRef, {
        intervalCheck: 750,
        resizeCheck: true,
        scrollCheck: true,
    });

    const previousIsVisible = usePrevious(isVisible);

    // Live catalog data selectors
    const sellerId = useAppSelector((state) => getCatalogSellerId(state, catalogId));
    const liveStatus = useAppSelector((state) => getLiveCatalogStatus(state, catalogId));
    const showLive = useAppSelector((state) => getShouldShowLive(state, catalogId));

    const live = showLive || liveStatus === 'live';

    React.useEffect(() => {
        if (!previousIsVisible && isVisible && !visibilityMessageSent) {
            dispatch(trackFeaturedCatalogView({ catalogId })).then(() => {
                setVisibilityMessageSent(true);

                // Fetch live catalog status from auction-engine if needed
                if (live) {
                    dispatch(fetchLiveCatalogStatusIfNeeded(catalogId, sellerId));
                    dispatch(fetchCatalogPresence(catalogId));
                }
            });
        }
    }, [dispatch, isVisible, previousIsVisible, catalogId, visibilityMessageSent, live, sellerId]);

    return (
        <div
            className={className}
            ref={adRef}
            suppressHydrationWarning
        >
            <CatalogCard
                catalogId={catalogId}
                className={cardClassname}
                featured
                lazyLoadImages={lazyLoadImages}
                trackClick={trackClick}
            />
        </div>
    );
};

export default FeaturedCatalogCardWrapper;
