import useIsNarrowerThan from '@/hooks/useIsNarrowerThan';

/**
 * React Hook that returns the how many categories to show
 * based on the window size
 *
 * @typedef {CalculateCategoriesCount} CalculateCategoriesCount
 * @returns number - indicating how many categories to show
 */

const useCalculateCategoriesCount = (): number => {
    const narrowerThan1000 = useIsNarrowerThan(1000);
    const narrowerThan551 = useIsNarrowerThan(551);
    if (narrowerThan551) {
        return 4;
    } else if (narrowerThan1000) {
        return 3;
    }

    return 4;
};

export default useCalculateCategoriesCount;
