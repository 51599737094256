import * as React from 'react';
import { Button } from '@liveauctioneers/hammer-ui-core/button';
import { FormattedMessage, FormattedNumber, useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { getAllUpcomingAuctionsUrl } from '@/utils/urls';
import { H2 } from '@liveauctioneers/hammer-ui-core/text';
import { Link } from '@liveauctioneers/hammer-ui-core/link';
import { trackUpcomingCatalogClick } from '@/redux/modules/analytics';
import { trustMetricsSelector } from '@/redux/modules/trustMetrics';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import CatalogGrid from '@/components/CatalogCard/CatalogGrid';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';
import useGetUpcomingCatalogIds from './hooks/useGetUpcomingCatalogIds';

type Props = {
    className?: string;
    testid?: string;
};

const HomePageUpcomingCatalogs = ({ className, testid }: Props) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const catalogIds = useGetUpcomingCatalogIds();
    const { upcomingCount }: any = useAppSelector(trustMetricsSelector, isEqual);

    const trackClick = React.useCallback(
        (catalogId: number) => dispatch(trackUpcomingCatalogClick(catalogId)),
        [dispatch]
    );

    return (
        <StyledUpcomingCatalogs
            className={className}
            id="upcomingCatalogs"
        >
            <StyledWrapper>
                <H2>
                    <FormattedMessage
                        id="online_auctions_count"
                        key="online_auctions_count"
                        values={{
                            liveCount: (
                                <FormattedNumber
                                    key="online_auctions_count"
                                    style="decimal"
                                    value={upcomingCount}
                                />
                            ),
                        }}
                    />
                </H2>
                <StyledCatalogGrid
                    catalogIds={catalogIds}
                    lazyLoadImages
                    trackClick={trackClick}
                />
                <StyledWatchWrapper data-testid={testid}>
                    <Link href={getAllUpcomingAuctionsUrl()}>
                        <StyledButton variant="outlined">
                            {formatMessage({
                                id: 'show_all_upcoming_auctions',
                            })}
                        </StyledButton>
                    </Link>
                </StyledWatchWrapper>
            </StyledWrapper>
        </StyledUpcomingCatalogs>
    );
};

export default React.memo<Props>(HomePageUpcomingCatalogs);

const StyledUpcomingCatalogs = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const StyledWrapper = styled.div`
    padding: 0 0 20px;
    position: relative;
    overflow: hidden;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    align-self: center;
    margin: 10px 0;

    h2 {
        text-align: left;
        margin: 0 0 20px;

        @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
            margin-bottom: 10px;
        }
    }
`;

const StyledCatalogGrid = styled(CatalogGrid)`
    flex: 1 0 auto;
    margin: 0 -10px;
    width: calc(100% + 20px);
    max-width: calc(100% + 20px);
`;

const StyledWatchWrapper = styled.div`
    text-align: center;
    text-decoration: none;

    button {
        text-decoration: none;
        display: inline-block;
    }
`;

const StyledButton = styled(Button)`
    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        margin: 15px 0 0;
    }
`;
