import { getCatalogIdsByIdsNotDone } from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getFeaturedCatalogIds } from '@/redux/modules/home/home.selectors';
import { useAppSelector } from '@/redux/hooks';

const useGetFeaturedCatalogIds = () => {
    const catalogIds = useAppSelector(getFeaturedCatalogIds);
    return useAppSelector((state) => getCatalogIdsByIdsNotDone(state, catalogIds));
};

export default useGetFeaturedCatalogIds;
