import * as React from 'react';
import { fetchItemSummariesIfNeeded } from '@/redux/modules/item/summary/itemSummary.actions';
import { fetchRecommendedItemIfNeeded, getSortedRecommendedItemIdsFromState } from '@/redux/modules/recommendedItem';
import { getRecommendedItemsUrl } from '@/utils/urls';
import { GlobalState } from '@/redux/store';
import { isUserDataLoaded, isUserDataLoading } from '@/redux/modules/account/user/user.selectors';
import { trackRecommendedItemClick } from '@/redux/modules/analytics';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import ItemShelf from '@/components/ItemShelf/ItemShelf';
import useDeepCompareEffect from 'use-deep-compare-effect';
import usePrevious from '@liveauctioneers/caterwaul-components/lib/hooks/usePrevious';

type Props = {
    className?: string;
};

const RecommendedItemCarousel = ({ className }: Props) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const itemIds = useAppSelector((state: GlobalState) => getSortedRecommendedItemIdsFromState(state, 12));
    const userDataLoading = useAppSelector(isUserDataLoading);
    const userDataLoaded = useAppSelector(isUserDataLoaded);
    const prevUserDataLoading = usePrevious(userDataLoading);

    React.useEffect(() => {
        if (!userDataLoading && prevUserDataLoading === true) {
            dispatch(fetchRecommendedItemIfNeeded(false));
        }
    }, [dispatch, userDataLoading, prevUserDataLoading]);

    useDeepCompareEffect(() => {
        if (itemIds.length) {
            dispatch(
                fetchItemSummariesIfNeeded({
                    identifier: 'RECOMMENDED-ITEM-Carousel',
                    itemIds,
                })
            );
        }
    }, [dispatch, itemIds]);

    const trackClick = React.useCallback(
        (itemId) => {
            dispatch(trackRecommendedItemClick(itemId));
        },
        [dispatch]
    );

    if (!itemIds.length && userDataLoaded) {
        return null;
    }

    return (
        <ItemShelf
            className={className}
            cta={itemIds.length > 6}
            identifier="recommendedItem"
            itemIds={itemIds || []}
            seeAllUrl={getRecommendedItemsUrl()}
            showPagination={false}
            title={formatMessage({ id: 'recommended_items' })}
            trackItemClick={trackClick}
        />
    );
};

export default React.memo<Props>(RecommendedItemCarousel);
