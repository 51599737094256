import * as React from 'react';
import { featuredCategoriesSelector, featuredCategoriesTitleSelector } from '@/redux/modules/featuredCategories';
import { FeaturedCategory } from '@/types/FeaturedCategory';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { H2 } from '@liveauctioneers/hammer-ui-core/text';
import { useAppSelector } from '@/redux/hooks';
import CategoryCard from './CategoryCard';
import styled from 'styled-components';
import useCalculateCategoriesCount from '@/hooks/useCalculateCategoriesCount';

type Props = {
    className?: string;
    testid?: string;
};

const CategoryModule = ({ className, testid }: Props) => {
    const title = useAppSelector(featuredCategoriesTitleSelector);
    const categories = useAppSelector(featuredCategoriesSelector);
    const categoryCount = useCalculateCategoriesCount();
    const categoriesToShow = categories.slice(0, categoryCount);

    const buildCategoryCards = React.useCallback((category: FeaturedCategory) => {
        const { imageUrl, label, link, title } = category;
        return (
            <CategoryCard
                imageUrl={imageUrl}
                key={title}
                label={label}
                link={link}
                title={title}
            />
        );
    }, []);

    return Boolean(categoriesToShow.length) ? (
        <StyledCategoryModule
            className={className}
            data-testid={testid}
        >
            <StyledShelfTitle>{title || <FormattedMessage id="browse_by_category" />}</StyledShelfTitle>
            <StyledCategoryList>{categoriesToShow.map(buildCategoryCards)}</StyledCategoryList>
        </StyledCategoryModule>
    ) : null;
};

export default React.memo<Props>(CategoryModule);

const StyledCategoryModule = styled.div`
    align-self: center;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

const StyledShelfTitle = styled(H2)`
    width: 100%;
    text-align: left;
`;

const StyledCategoryList = styled.div`
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
`;
