import isEmpty from 'lodash/isEmpty';

export const email = (value: string) => {
    const emailRegex =
        /^(?:(?:[^<>()[\]\\.,;:\s@"]+(?:\.[^<>()[\]\\.,;:\s@"]+)*)|(?:".+"))@[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+?$/;
    return emailRegex.test(value);
};

export const phone = (value: string) => {
    const phoneRegex =
        /(?:(?:(\s*\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\)?\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/;
    return phoneRegex.test(value);
};

export const internationalPhone = (value: string) => {
    return Boolean(value);
};

export const required = (value: any) => {
    if (typeof value === 'number') {
        return true;
    }
    return !isEmpty(value);
};

export const maxLength = (value: string, max: number) => {
    return !isEmpty(value) && value.length <= max;
};

export const wholeNumbersStartingWithOne = (value: string) => {
    const regExp = /^[1-9][0-9]*$/;
    return regExp.test(value);
};

export const validatePassword = (value: string) => {
    return required(value) && value.length >= 7;
};

export const validateInternationalZipCode = (zipCode: string) => {
    const zipCodeValidationRegex = /^[a-zA-Z0-9-_]+$/;
    return zipCodeValidationRegex.test(zipCode);
};
