import { Carousel } from '@liveauctioneers/hammer-ui-core/carousel';
import { getFeaturedCatalogsUrl } from '@/utils/urls';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { useMemo } from 'react';
import CarouselLoadingWrapper from '@/components/CarouselLoadingWrapper/CarouselLoadingWrapper';
import FeaturedCatalogCardWrapper from './FeaturedCatalogCardWrapper';
import useGetFeaturedCatalogIds from './hooks/useGetFeaturedCatalogIds';

type Props = {
    className?: string;
};

const FeaturedCatalogCarousel = ({ className }: Props) => {
    const { formatMessage } = useIntl();
    const catalogIds = useGetFeaturedCatalogIds();

    const carouselCards = useMemo(
        () =>
            catalogIds.map((catalogId: number, index: number) => ({
                card: (
                    <div className="sm:w-[450px] w-[590px] focus:outline-none xsMax:w-[300px]">
                        <FeaturedCatalogCardWrapper
                            cardClassname="block max-w-full"
                            catalogId={catalogId}
                            lazyLoadImages={index > 2}
                        />
                    </div>
                ),
                key: `${catalogId}-featured`,
            })),
        [catalogIds]
    );

    return (
        <CarouselLoadingWrapper
            className={className}
            id="featuredCatalog"
        >
            <Carousel
                action={{
                    path: getFeaturedCatalogsUrl(),
                    text: formatMessage({ id: 'see_all' }),
                }}
                cards={carouselCards}
                title={formatMessage({ id: 'featured_auctions' })}
            />
        </CarouselLoadingWrapper>
    );
};

export default FeaturedCatalogCarousel;
