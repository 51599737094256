import { getHasLoadedUpcomingIds, getUpcomingItemIds } from '@/redux/modules/upcoming/items/upcomingItems.selectors';
import { getItemSummaries } from '@/redux/modules/item/summary/itemSummary.selectors';
import { memo, useCallback } from 'react';
import { orderByCatalogSaleStartAscending } from '@/redux/modules/sorting';
import { trackComingUpItemsClick } from '@/redux/modules/analytics';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import isEqual from 'react-fast-compare';
import ItemShelf from '@/components/ItemShelf/ItemShelf';

type Props = {
    className?: string;
    testid?: string;
};

const ComingUpCarousel = ({ className, testid }: Props) => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const upcomingItemIds = useAppSelector(getUpcomingItemIds);
    const items = useAppSelector((state) => getItemSummaries(state, upcomingItemIds), isEqual);
    const sortedItems = orderByCatalogSaleStartAscending(items);
    const itemIds = sortedItems.slice(0, 12).map(({ itemId }) => itemId);
    const upcomingItemsLoaded = useAppSelector(getHasLoadedUpcomingIds);

    const trackClick = useCallback((itemId) => dispatch(trackComingUpItemsClick(itemId)), [dispatch]);

    if (!itemIds.length && upcomingItemsLoaded) {
        return null;
    }

    return (
        <ItemShelf
            allowBidding
            className={className}
            data-testid={testid}
            identifier="comingUp"
            itemIds={itemIds}
            showBidStatus
            showPagination={false}
            title={formatMessage({ id: 'coming_up_title' })}
            trackItemClick={trackClick}
        />
    );
};

export default memo<Props>(ComingUpCarousel);
