import { Carousel } from '@liveauctioneers/hammer-ui-core/carousel';
import { cn } from '@liveauctioneers/hammer-ui-theme/cn';
import { ComponentProps, memo, ReactNode, useCallback, useMemo } from 'react';
import { H2 } from '@liveauctioneers/hammer-ui-core/text';
import { maxItemsForMobile } from '@/pages/Item/components/ItemPageCarousel/constants/ItemPageCarousels.constants';
import { RouteButton } from '../RouteButton/RouteButton';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import FluidItemGrid from '../GridItemCard/FluidItemGrid/FluidItemGrid';
import isEqual from 'react-fast-compare';
import ItemCard from '@/components/GridItemCard/Card/Card';
import noop from '@/utils/noop';
import SideScroller from '@/components/SideScroller/SideScroller';
import Throbber from '@liveauctioneers/caterwaul-components/lib/Throbber/Throbber';
import TimedOrCategorySearchCard, {
    CarouselTimedOrCategorySearchCard,
} from '@/components/GridItemCard/Card/MultiCardOptions/TimedOrCategorySearchCard/TimedOrCategorySearchCard';
import useIsWiderThan from '@/hooks/useIsWiderThan';

const GRID_CHANGE_WIDTH = 550;

type Props = {
    allowBidding?: boolean;
    className?: string;
    cta?: boolean;
    hideLiveButton?: boolean;
    identifier: string;
    itemIds: number[];
    links?: ReactNode;
    seeAllUrl?: string;
    showBidStatus?: boolean;
    showSoldBadge?: boolean;
    testid?: string;
    title: string;
    trackItemClick?: Function;
} & Partial<ComponentProps<typeof Carousel>>;

const ItemShelf = (props: Props) => {
    const {
        className = '',
        cta = false,
        identifier,
        itemIds = [],
        links = false,
        seeAllUrl = '',
        testid,
        title,
        trackItemClick = noop,
        ...carouselProps
    } = props;
    const wide = useIsWiderThan(GRID_CHANGE_WIDTH);
    const { formatMessage } = useIntl();

    const trackClick = useCallback(
        (itemId) => {
            trackItemClick(itemId);
        },
        [trackItemClick]
    );

    const carouselCards = useMemo(
        () =>
            itemIds?.map((itemId, index) => ({
                card: (
                    <ItemCard
                        itemCardOption={CarouselTimedOrCategorySearchCard}
                        itemId={itemId}
                        key={`${index}-${identifier}`}
                        trackClick={() => trackClick(itemId)}
                    />
                ),
                key: `${index}-${identifier}`,
            })),
        [identifier, itemIds, trackClick]
    );

    if (!itemIds.length) {
        return (
            <div
                className={cn(
                    'flex h-[361px] w-full max-w-[1200px] items-center justify-center bg-background-subdued',
                    className
                )}
                id={identifier}
            >
                <Throbber />
            </div>
        );
    }

    const fullCarouselHeader = {
        ...(seeAllUrl && {
            action: {
                path: seeAllUrl,
                text: formatMessage({ id: 'see_all' }),
            },
        }),
        title,
    };

    return (
        <div
            className={cn('flex flex-col bg-background-primary transition-all duration-200 ease-in', className)}
            data-testid={testid}
            id={identifier}
        >
            {Boolean(links) && links}
            {wide ? (
                <SideScroller
                    cards={carouselCards}
                    className="md:w-[calc(100%-20px)] md:ml-[20px] w-full max-w-[1200px] self-center"
                    useOverlayControls
                    {...carouselProps}
                    {...(!links && fullCarouselHeader)}
                />
            ) : (
                <>
                    {!links && <H2 className="ml-[20px] xsMax:ml-0">{title}</H2>}
                    <FluidItemGrid
                        className="mb-[35px] px-[20px]"
                        itemCardOption={TimedOrCategorySearchCard}
                        itemIds={itemIds.slice(0, maxItemsForMobile)}
                    />
                    {cta && (
                        <div className="mx-auto text-center">
                            <RouteButton
                                className="w-[250px] max-w-[calc(100%-40px)]"
                                to={seeAllUrl}
                            >
                                {formatMessage({ id: 'see_all' })}
                            </RouteButton>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default memo<Props>(ItemShelf, isEqual);
