import * as React from 'react';
import { addImageParams } from '@/utils/urls';
import { Ref } from '@/types/Ref';
import { trackAdUnitClick, trackAdUnitVisible } from '@/redux/modules/analytics';
import { useAppDispatch } from '@/redux/hooks';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import styled from 'styled-components';
import useVisibilitySensor from '@rooks/use-visibility-sensor';

type Props = {
    destinationURL: string;
    featuredAuctioneerId: number;
    houseId: number;
    houseName: string;
    imageURL: string;
};

const imageWidth = 184;
const imageHeight = 104;

const AuctioneerCard = ({ destinationURL, featuredAuctioneerId, houseId: sellerId, houseName, imageURL }: Props) => {
    const dispatch = useAppDispatch();
    const url = destinationURL.includes('liveauctioneers.com/auctioneer/')
        ? `/auctioneer${destinationURL.split('/auctioneer')[1]}`
        : destinationURL;
    const isInternalUrl = !url.includes('http');
    const safeUrl = imageURL.trim().replace(' ', '%20');
    const src = addImageParams({
        quality: 90,
        url: safeUrl,
        width: imageWidth,
    });
    const highDpiSrc = addImageParams({
        quality: 90,
        url: safeUrl,
        width: imageWidth * 2,
    });

    const targetRef: Ref = React.useRef<HTMLDivElement>(null);
    const { isVisible } = useVisibilitySensor(targetRef, {
        intervalCheck: false,
        resizeCheck: true,
        scrollCheck: true,
    });

    const [visibilityMessageSent, setVisibilityMessageSent] = React.useState(false);

    React.useEffect(() => {
        if (isVisible && !visibilityMessageSent && featuredAuctioneerId) {
            dispatch(
                trackAdUnitVisible({
                    adUnitId: featuredAuctioneerId,
                    consignment: false,
                    page: 'home',
                    sellerId,
                })
            );
            setVisibilityMessageSent(true);
        }
    }, [dispatch, featuredAuctioneerId, isVisible, sellerId, visibilityMessageSent]);

    const trackClick = React.useCallback(() => {
        Boolean(featuredAuctioneerId) &&
            dispatch(
                trackAdUnitClick({
                    adUnitId: featuredAuctioneerId,
                    consignment: false,
                    page: 'home',
                    sellerId,
                })
            );
    }, [dispatch, featuredAuctioneerId, sellerId]);

    return (
        <StyledAuctioneerCard
            data-testid="auctioneer-card"
            onClick={trackClick}
            route={isInternalUrl}
            to={url}
        >
            <StyledImage
                alt={houseName}
                height={imageHeight}
                loading="lazy"
                src={src}
                srcSet={`${highDpiSrc} 2x`}
                width={imageWidth}
            />
            <StyledVisibilityElement ref={targetRef} />
        </StyledAuctioneerCard>
    );
};

export default AuctioneerCard;

const StyledAuctioneerCard = styled(Link)`
    cursor: pointer;
    height: 104px;
    width: 184px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 5px;
`;

const StyledImage = styled.img`
    max-height: 104px;
    max-width: 184px;
`;

const StyledVisibilityElement = styled.div`
    height: 104px;
    width: 180px;
    background-color: transparent;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`;
