import * as React from 'react';
import { addImageParams, AddImageParamTypes } from '@/utils/urls';
import { trackFeaturedCategoryClick } from '@/redux/modules/analytics';
import { useAppDispatch } from '@/redux/hooks';
import Link from '@liveauctioneers/caterwaul-components/lib/Link/Link';
import styled from 'styled-components';

type Props = {
    imageUrl: string;
    label: string;
    link: string;
    title: string;
};

const CategoryCard = ({ imageUrl, label, link, title }: Props) => {
    const dispatch = useAppDispatch();
    const trackClick = React.useCallback(() => dispatch(trackFeaturedCategoryClick(label)), [dispatch, label]);

    const imageParams: AddImageParamTypes = {
        height: 160,
        url: imageUrl,
    };

    return (
        <StyledCategoryCard onClick={trackClick}>
            <StyledCategoryLink
                route
                to={link}
            >
                <StyledImageWrapper>
                    <StyledImage
                        alt={title}
                        loading="lazy"
                        src={addImageParams(imageParams)}
                    />
                </StyledImageWrapper>
                <StyledTitle>{title}</StyledTitle>
            </StyledCategoryLink>
        </StyledCategoryCard>
    );
};

export default React.memo<Props>(CategoryCard);

const StyledCategoryCard = styled.div`
    justify-content: center;
    display: flex;
    flex: 0 1 auto;
    position: relative;
    margin-top: 20px;

    @media (min-width: 550px) {
        width: calc(33.333% - 10px);
    }

    @media (min-width: 1000px) {
        width: calc(25% - 15px);
    }

    @media (max-width: 550px) {
        width: calc(50% - 7.5px);
    }
`;

const StyledCategoryLink = styled(Link)`
    && {
        background-color: ${({ theme }) => theme.colors.white};
        display: block;
        text-decoration: none;
        position: relative;
        width: 100%;

        &:hover span {
            color: ${({ theme }) => theme.colors.blue200};
        }
    }
`;

const StyledImageWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.grey500};
    width: 100%;
    display: block;
    justify-content: center;
    overflow: hidden;
    padding-bottom: 66.66666%;
    position: relative;
`;

const StyledImage = styled.img`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: block;
    max-height: 100%;
    max-width: 100%;
`;

const StyledTitle = styled.div`
    z-index: 1000;
    bottom: 0;
    width: 100%;
    padding: 15px 15px 15px 0;
    text-align: left;
    color: ${({ theme }) => theme.colors.black};
    font-size: 18px;
    pointer-events: none;
    letter-spacing: 1px;
    font-family: ${({ theme }) => theme.typography.fontSecondary};

    @media (max-width: 550px) {
        font-size: 15px;
        padding: 10px 10px 10px 0;
    }
`;
