import { getForYouUrl } from '@/utils/urls';
import { Link } from '@liveauctioneers/hammer-ui-core/link';
import { memo, useMemo } from 'react';
import { SavedSearch } from '@/redux/api/saveSearch';
import { toFacetString, toSavedSearchUrl } from '@/utils/savedSearchFacets';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import isEqual from 'react-fast-compare';
import SideScroller from '@/components/SideScroller/SideScroller';

type Props = {
    savedSearches: SavedSearch[];
};

const FollowedSearchesLinks = ({ savedSearches }: Props) => {
    const intl = useIntl();
    const { formatMessage } = useIntl();

    const carouselCards = useMemo(
        () =>
            savedSearches?.map((savedSearch, index) => {
                const { facets, savedSearchId } = savedSearch;
                const savedSearchUrl = savedSearch?.searchURL || toSavedSearchUrl(savedSearch.facets);
                return {
                    card: (
                        <Link
                            className="text-text-light shadow-sm focus:bg-background-subtle mb-2xs ml-0 mr-md inline-flex h-9 max-w-[220px] items-center rounded-[2px] bg-surface-100 px-md py-0 font-primary text-sm tracking-wider no-underline shadow-02 first:ml-[1px] last:mr-[4px] hover:text-link-hover hover:no-underline focus:text-link-primary focus:no-underline"
                            href={savedSearchUrl}
                            key={savedSearchId}
                        >
                            <div className="text-primary line-clamp-1 overflow-hidden font-medium focus:font-bold focus:text-primary-500">
                                {toFacetString(facets, intl)}
                            </div>
                        </Link>
                    ),
                    key: `${index}-${savedSearchId}`,
                };
            }),
        [intl, savedSearches]
    );

    return (
        <div className="mb-md flex p-0">
            <SideScroller
                action={{
                    path: getForYouUrl(),
                    text: formatMessage({ id: 'see_all' }),
                }}
                cards={carouselCards}
                className="max-w-page md:w-[calc(100%-40px)] md:ml-lg mt-0 w-full self-center"
                title={formatMessage({ id: 'followedSearchCarousel.header' })}
            />
        </div>
    );
};

export default memo<Props>(FollowedSearchesLinks, isEqual);
