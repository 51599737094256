import { DEFAULT_CARD_DIMENSIONS } from './constants/defaultCardDimensions.constant';
import { ItemCardInfo } from '../types/types';
import { useTheme } from 'styled-components';
import useIsWiderThan from '@/hooks/useIsWiderThan';

type Props = {
    className?: string;
    itemCardOption: ItemCardInfo;
    itemId: number;
    trackClick?: (itemId: number, index: number) => void;
};

export default function ItemCard({ className, itemCardOption, itemId, trackClick }: Props) {
    const { dimensions, ItemCardComponent } = itemCardOption;

    const theme = useTheme();
    const desktop = useIsWiderThan(theme.breakpoints.tabletNarrowWidthNum);

    let dimension = desktop ? DEFAULT_CARD_DIMENSIONS.desktop : DEFAULT_CARD_DIMENSIONS.mobile;
    if (dimensions?.desktop && dimensions?.mobile) {
        dimension = dimensions.desktop > dimensions.mobile ? dimensions.desktop : dimensions.mobile;
    }

    return (
        <ItemCardComponent
            className={className}
            dimension={dimension}
            itemId={itemId}
            key={itemId}
            trackClick={trackClick}
        />
    );
}
