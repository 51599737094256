import { Carousel } from '@liveauctioneers/hammer-ui-core/carousel';
import { getAuctionCalendarUrlWithParams } from '@/utils/urls';
import { getCatalogsForFollowedSellers } from '@/redux/modules/filteredItems';
import { getFollowedSellerIds } from '@/redux/modules/followSeller';
import { memo, useCallback, useMemo } from 'react';
import { trackAuctionsYouMayLikeClick } from '@/redux/modules/analytics';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import CarouselLoadingWrapper from '../CarouselLoadingWrapper/CarouselLoadingWrapper';
import CatalogCard from '../CatalogCard/CatalogCard';
import isEqual from 'react-fast-compare';
import styled from 'styled-components';

type Props = {
    className?: string;
};

const AuctionsYouMayLikeCarousel = ({ className }: Props) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const followedSellerIds = useAppSelector(getFollowedSellerIds, isEqual);
    const catalogIds = useAppSelector(getCatalogsForFollowedSellers);

    const trackClick = useCallback(
        (catalogId: number) => dispatch(trackAuctionsYouMayLikeClick(catalogId)),
        [dispatch]
    );

    const catalogCards = useMemo(
        () =>
            catalogIds.map((catalogId) => ({
                card: (
                    <StyledCard key={`${catalogId}-youmay-like`}>
                        <CatalogCard
                            catalogId={catalogId}
                            trackClick={() => trackClick(catalogId)}
                        />
                    </StyledCard>
                ),
                key: `${catalogId}-youmay-like`,
            })),
        [catalogIds, trackClick]
    );

    if (catalogIds.length < 4) {
        return null;
    }

    return (
        <StyledDivContainer className={className}>
            <StyledSliderWrapper>
                <CarouselLoadingWrapper>
                    <Carousel
                        action={{
                            path: getAuctionCalendarUrlWithParams({
                                house_id: followedSellerIds,
                            }),
                            text: formatMessage({ id: 'see_all' }),
                        }}
                        cards={catalogCards}
                        title={formatMessage({ id: 'auctions_you_may_like' })}
                    />
                </CarouselLoadingWrapper>
            </StyledSliderWrapper>
        </StyledDivContainer>
    );
};

export default memo<Props>(AuctionsYouMayLikeCarousel);

const StyledDivContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledSliderWrapper = styled.div`
    max-width: ${({ theme }) => theme.breakpoints.pageWidth};
    width: 100%;
    text-align: center;
    padding: 0;
    align-self: center;
    position: relative;
`;

const StyledCard = styled.div`
    width: 285px;

    .featuredCardClass {
        display: block;
        max-width: 100%;
    }

    &:focus {
        outline: 0;
    }
`;
