import { Carousel } from '@liveauctioneers/hammer-ui-core/carousel';
import { getFeaturedAuctioneers } from '@/redux/modules/home/home.selectors';
import { useAppSelector } from '@/redux/hooks';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import AuctioneerCard from './AuctioneerCard';
import CarouselLoadingWrapper from '@/components/CarouselLoadingWrapper/CarouselLoadingWrapper';

const FeaturedAuctioneers = () => {
    const { formatMessage } = useIntl();
    const {
        breakpoints: { pageWidthNum },
    } = useTheme();

    const featuredAuctioneers = useAppSelector(getFeaturedAuctioneers);

    const carouselCards = useMemo(
        () =>
            featuredAuctioneers?.map(({ destinationURL, featuredAuctioneerId, houseId, houseName, imageURL }) => ({
                card: (
                    <AuctioneerCard
                        destinationURL={destinationURL}
                        featuredAuctioneerId={featuredAuctioneerId}
                        houseId={houseId}
                        houseName={houseName}
                        imageURL={imageURL}
                        key={`${featuredAuctioneerId}-${houseId}`}
                    />
                ),
                key: `${featuredAuctioneerId}-${houseId}`,
            })),
        [featuredAuctioneers]
    );

    return (
        <CarouselLoadingWrapper className={`mx-auto w-[calc(100%-40px)] max-w-[${pageWidthNum - 40}px]`}>
            <Carousel
                cards={carouselCards}
                title={formatMessage({ id: 'featuredAuctioneers.featured' })}
            />
        </CarouselLoadingWrapper>
    );
};

export default FeaturedAuctioneers;
