import HeroBannerFeaturedCardList from './HeroBannerFeaturedCardList';
import JoinContainer from './JoinContainer';
import React from 'react';
import styled from 'styled-components';

const HeroBanner = () => {
    return (
        <HeroBannerContainer data-testid="treasureHuntingContainer">
            <AbContainer>
                <JoinContainer />
                <HeroBannerFeaturedCardList />
            </AbContainer>
        </HeroBannerContainer>
    );
};

export default HeroBanner;

const HeroBannerContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const AbContainer = styled.div`
    display: flex;
    height: 100%;
    max-height: 238px;
    width: calc(100% - 40px);
    max-width: 1200px;
    margin: 0 auto;

    @media (max-width: 930px) {
        flex-direction: column;
        max-height: none;
    }
`;
