import { CUSTOM_MOBILE_BREAKPOINT, GridBreakpoints } from '@/components/GridItemCard/FluidItemGrid/FluidItemGrid';
import { mergedCaterwaulAndHammerUIThemes as theme } from '@/theme/mergedCaterwaulAndHammerUIThemes';

export const maxItemsForMobile = 6;
export const CUSTOM_TABLET_BREAKPOINT = 600;
export const CUSTOM_MOBILE_LG_BREAKPOINT = 450;

export const breakpoints: GridBreakpoints = {
    breakpoints: {
        [CUSTOM_MOBILE_BREAKPOINT]: {
            column: 2,
        },
        [CUSTOM_MOBILE_LG_BREAKPOINT]: {
            column: 3,
            gap: {
                column: theme.spacing.mdValue,
                row: theme.spacing.lgValue,
            },
        },
        [CUSTOM_TABLET_BREAKPOINT]: {
            column: 4,
            gap: {
                column: theme.spacing.mdValue,
                row: theme.spacing.lgValue,
            },
        },
    },
    column: 1,
    gap: {
        column: theme.spacing.smValue,
        row: theme.spacing.smValue,
    },
};
