import { getCatalogIdsByIdsNotDone } from '@/redux/modules/catalog/catalogs/catalog.selectors';
import { getUpcomingCatalogIds } from '@/redux/modules/home/home.selectors';
import { useAppSelector } from '@/redux/hooks';

const useGetUpcomingCatalogIds = () => {
    const catalogIds = useAppSelector(getUpcomingCatalogIds);
    const upcomingCatalogs = useAppSelector((state) => getCatalogIdsByIdsNotDone(state, catalogIds));
    return upcomingCatalogs.slice(0, 9);
};

export default useGetUpcomingCatalogIds;
