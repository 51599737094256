import * as React from 'react';
import { getForYouUrl } from '@/utils/urls';
import { getHasSavedSearches } from '@/redux/modules/saveSearch';
import {
    getHasSavedSearchItems,
    getSavedSearchItemIds,
    LOADING_SAVED_SEARCH_ITEM_IDS,
    savedSearchSearchesSelector,
} from '@/redux/modules/savedSearch';
import { trackFromFollowedSearchClick } from '@/redux/modules/analytics';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import FollowedSearchesLinks from './FollowedSearchesLinks';
import ItemShelf from '@/components/ItemShelf/ItemShelf';

type Props = {
    className?: string;
};

const FollowedSearchCarousel = ({ className }: Props) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const savedSearches = useAppSelector(savedSearchSearchesSelector);
    const itemIds = useAppSelector(getSavedSearchItemIds);
    const hasSavedSearches = useAppSelector(getHasSavedSearches);
    const hasSavedSearchResults = useAppSelector(getHasSavedSearchItems);

    const trackClick = React.useCallback((itemId) => dispatch(trackFromFollowedSearchClick(itemId)), [dispatch]);

    if (!hasSavedSearchResults || !hasSavedSearches) {
        return null;
    }

    return (
        <ItemShelf
            className={className}
            cta={itemIds.length > 6}
            identifier={LOADING_SAVED_SEARCH_ITEM_IDS}
            itemIds={itemIds}
            links={<FollowedSearchesLinks savedSearches={savedSearches} />}
            seeAllUrl={getForYouUrl()}
            showPagination={false}
            title={formatMessage({ id: 'followedSearchCarousel.header' })}
            trackItemClick={trackClick}
        />
    );
};

export default React.memo<Props>(FollowedSearchCarousel);
