import * as React from 'react';
import { fetchItemSummariesIfNeeded } from '@/redux/modules/item/summary/itemSummary.actions';
import { fetchRecentItemsIfNeeded, getLimitedSortedRecentItemIdsFromState } from '@/redux/modules/recentItem';
import { getRecentItemsUrl } from '@/utils/urls';
import { GlobalState } from '@/redux/store';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { trackRecentItemClick } from '@/redux/modules/analytics';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useIntl } from '@liveauctioneers/hammer-ui-core/intl';
import ItemShelf from '@/components/ItemShelf/ItemShelf';
import useDeepCompareEffect from 'use-deep-compare-effect';

type Props = {
    className?: string;
};

const RecentItemsCarousel = ({ className }) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const itemIds = useAppSelector((state: GlobalState) => getLimitedSortedRecentItemIdsFromState(state, 12));
    const isLoggedIn = useAppSelector(isUserLoggedIn);

    React.useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchRecentItemsIfNeeded());
        }
    }, [dispatch, isLoggedIn]);

    useDeepCompareEffect(() => {
        if (itemIds.length) {
            dispatch(
                fetchItemSummariesIfNeeded({
                    identifier: 'RECENT-ITEM-Carousel',
                    itemIds,
                })
            );
        }
    }, [dispatch, itemIds]);

    const trackClick = React.useCallback(
        (itemId) => {
            dispatch(trackRecentItemClick(itemId));
        },
        [dispatch]
    );

    if (!Boolean(itemIds.length)) {
        return null;
    }

    return (
        <ItemShelf
            className={className}
            cta={itemIds.length > 6}
            identifier="recentItems"
            itemIds={itemIds}
            seeAllUrl={getRecentItemsUrl()}
            testid="recentlyViewedItem"
            title={formatMessage({ id: 'recently_viewed' })}
            trackItemClick={trackClick}
        />
    );
};

export default React.memo<Props>(RecentItemsCarousel);
