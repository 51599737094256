import { cn } from '@liveauctioneers/hammer-ui-theme/cn';
import { fetchAllHomeData } from '@/redux/modules/home/home.actions';
import { getFeaturedAuctioneersExist } from '@/redux/modules/home/home.selectors';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { recordAnalyticsForHomePageView } from '@/redux/modules/analytics';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useCompleteRegistrationPromptExperiment } from '@/hooks/kameleoon/CompleteRegistrationPrompt/useCompleteRegistrationPrompt';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { waitToDispatch } from '@/utils/waitToDispatch/waitToDispatch';
import AuctionsYouMayLikeCarousel from '@/components/YouMayLikeCarousel/AuctionsYouMayLikeCarousel';
import CategoryModule from '@/components/CategoryModule/CategoryModule';
import ComingUpCarousel from '@/components/ComingUp/ComingUpCarousel';
import CompleteAccountBanner from '@/components/CompleteAccountBanner/CompleteAccountBanner';
import FeaturedAuctioneers from '@/components/FeaturedAuctioneers/FeaturedAuctioneers';
import FeaturedCatalogCarousel from '@/components/FeaturedCatalogCarousel/FeaturedCatalogCarousel';
import FollowedSearchCarousel from '@/components/FollowedSearchCarousel/FollowedSearchCarousel';
import HeroBanner from '@/components/Hero/HeroBanner';
import HomePageHeaderMeta from './HomePageHeaderMeta';
import HomePageUpcomingCatalogs from './HomePageUpcomingCatalogs';
import RecentItemsCarousel from '@/components/RecentItems/RecentItemsCarousel';
import RecommendedItemCarousel from '@/components/RecommendedItem/RecommendedItemCarousel';
import SearchFollowSuggestions from '@/components/SearchFollowSuggestions/SearchFollowSuggestions';
import TrendingItemsCarousel from '@/components/TrendingItems/TrendingItemsCarousel';
import usePrevious from '@liveauctioneers/caterwaul-components/lib/hooks/usePrevious';
const sharedSectionClasses = 'mdMax:w-[calc(100%-40px)] w-full mx-auto my-[16px] max-w-[1200px]';
const sectionClasses = 'w-[calc(100%-40px)] max-w-[1200px] mx-auto my-[16px]';

const HomePage = () => {
    const dispatch = useAppDispatch();
    const isLoggedIn = useAppSelector(isUserLoggedIn);
    const wasLoggededOut = usePrevious(!isLoggedIn);
    const featuredAuctioners = useAppSelector(getFeaturedAuctioneersExist);
    const location = useLocation();
    const hasCompleteRegistrationPromptVariant = useCompleteRegistrationPromptExperiment();

    useEffect(() => {
        waitToDispatch(recordAnalyticsForHomePageView, dispatch, location);
    }, [dispatch, location]);

    useEffect(() => {
        // if you were logged out when you landed on this page, we need to reload the data
        if (wasLoggededOut && isLoggedIn) {
            dispatch(fetchAllHomeData());
        }
    }, [isLoggedIn, dispatch, wasLoggededOut]);

    return (
        <section className="relative flex w-full flex-col content-center">
            <HomePageHeaderMeta />
            {!hasCompleteRegistrationPromptVariant && (
                <CompleteAccountBanner className="mx-auto mt-5 w-[calc(100%-40px)] max-w-[1200px]" />
            )}
            {!isLoggedIn && <HeroBanner />}
            <FeaturedCatalogCarousel className={sharedSectionClasses} />
            {isLoggedIn && (
                <ComingUpCarousel
                    className={sharedSectionClasses}
                    testid="comingUpForAuctions"
                />
            )}
            {featuredAuctioners && <FeaturedAuctioneers />}
            {isLoggedIn && <FollowedSearchCarousel className={sectionClasses} />}
            {isLoggedIn && <RecommendedItemCarousel className={sharedSectionClasses} />}
            {isLoggedIn && <AuctionsYouMayLikeCarousel className={sharedSectionClasses} />}
            <SearchFollowSuggestions className={sharedSectionClasses} />
            <RecentItemsCarousel className={sharedSectionClasses} />
            {isLoggedIn && (
                <CategoryModule
                    className={cn(sharedSectionClasses, sectionClasses)}
                    testid="categoryModule"
                />
            )}
            <TrendingItemsCarousel className={sharedSectionClasses} />
            <HomePageUpcomingCatalogs className={sharedSectionClasses} />
        </section>
    );
};

export default HomePage;
